<template>
<div class="header-menu-group" :class="css_class" v-if="width">
    <div class="row">
        <div v-for="(l, i) in list" :key="i" class="col-lg">
            <div v-for="(item, index) in l" :key="index">
                <span v-if="!item.isItem" class="headline">
                    <a :href="(item.url != '' ? '#' : item.url)">
                        <h2>{{item.title}}</h2>
                    </a>
                    <span class="hide-content">{{item.description}}</span>
                </span>
                <span v-if="item.isItem" class="menu-item">
                    <header-menu-block-item :item="item" />
                </span>
                <!-- <span v-if="item.isItem"> {{item}}</span> -->
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'HeaderMenuBlockItemGroup',
    components: {
        HeaderMenuBlockItem: () => import('./HeaderMenuBlockItem.vue'),
    },
    data() {
        return {}
    },
    props: {
        group: {
            type: Array,
            default: () => []
        },
        width: {
            type: Number,
            default: 1
        },
        css_class: {
            type: String
        },
        depth: {
            type: Number
        },
        total: {
            type: Number
        }
    },
    computed: {
        list: function () {
            let out = {};
            let objIndex = 0;
            let counter = 0;
            for (const index in this.group) {
                if (Object.hasOwnProperty.call(this.group, index)) {
                    out[objIndex] = [];
                    out[objIndex].push({
                        isItem: false,
                        ...this.group[index]
                    })

                    for (const innerIndex in this.group[index].children) {
                        if (Object.hasOwnProperty.call(this.group[index].children, innerIndex)) {
                            out[objIndex].push({
                                isItem: true,
                                ...this.group[index].children[innerIndex]
                            })
                            counter++;

                            if (counter % this.depth == 0 && this.total > counter) {
                                objIndex++;
                                out[objIndex] = [];
                            }
                        }
                    }

                }
            }

            return out;
        }
    }
};
</script>

<style lang="scss" scoped>
.header-menu-group {
    border-right: 1px solid #eee;

    &:last-child {
        border: none;
    }

    .hide-content {
        display: none;
    }

    span.headline {
        width: calc(100% - 30px);
        display: block;
        border-bottom: 1px solid #ddd;
        margin: 0 15px 30px 15px;
        padding-bottom: 10px;

        a {
            font-weight: 500;
            text-transform: uppercase;
            position: relative;
            color: #0d0e10;

            &:focus-visible {
                outline: none;
            }

            &:hover {
                text-decoration: none;
            }

            h2 {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 991px) {
    .header-menu-group {
        border-right: none;

        span.headline {
            border-bottom: 1px solid #ddd;

            a {
                font-weight: 500;
                color: white;

                h2 {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
